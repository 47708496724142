import styled from "styled-components";

export const DiagramTable = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    gap: 32px;
  }
`;

export const WrapperDonat = styled.div`
  flex: 1;
`;

export const WrapperTable = styled.div`
  flex: 1;
  @media screen and (min-width: 1280px) {
  }
`;
